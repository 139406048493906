<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Distributors</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="accent" small to="/new-bp-master-data">
          <v-icon left dark>mdi-plus</v-icon>New Distributor
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="search" label="Search"></v-text-field>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="distributors"
              :search="search"
            >
              <template v-slot:item.CardName="{ item }">
                <v-btn text :to="`/outlet/${item.id}`" color="accent">{{ item.CardName }}</v-btn>
              </template>
              <template v-slot:item.outlets.descript="{ item }">
                <v-btn
                  text
                  :to="`/setup/territory-details/${item.Territory}`"
                >{{ item.outlets.descript }}</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <snackbar refs="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      distributors: [],
      headers: [
        { text: "Name", value: "CardName" },
        { text: "Address", value: "Address" },
        { text: "Region", value: "territory.descript" },
        { text: "Email", value: "Email" },
        { text: "Phone No.", value: "Phone1" },
        { text: "More", value: "Description" },
      ],
    };
  },
  methods: {
    getDistributors() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/getDistributors`)
        .then((res) => {
          console.log(res, "r");
          self.distributors = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getDistributors();
  },
};
</script>

<style scoped>
</style>